<template>
  <v-alert
    border="left"
    color="primary"
    :colored-border="true"
    class="py-2 file-title-max-lenght"
  >
    <v-layout align-center>
      <v-icon>mdi-paperclip</v-icon>
      <h3 class="ml-1 overflow-auto">
        {{ file }}
      </h3>
    </v-layout>
    <v-layout class="mx-2" v-if="checkShowItem">
      <v-select
        v-if="!item.TranslatedFiles && !item.ReadOnly"
        v-model="getSetItemDefaultLanguage"
        :items="itemLanguages"
        :label="$t('Language')"
        class="max-w-select-lang mr-2 mt-4"
        hide-details="auto"
        outlined
        dense
      ></v-select>
      <v-layout v-if="!item.TranslatedFiles" align-end>
        <div
          v-for="(translatedVal, translatedValueIndex) in item.FileAlias[file]"
          :key="translatedValueIndex"
        >
          <v-text-field
            v-if="
              getSetItemDefaultLanguage == translatedValueIndex &&
              !item.ReadOnly
            "
            v-model="item.FileAlias[file][translatedValueIndex]"
            class="mr-2 mt-4 mb-3 max-width-alias-field"
            hide-details="auto"
            :label="$t('File alias')"
            outlined
            dense
          >
          </v-text-field>
          <p
            v-else-if="
              getSetItemDefaultLanguage == translatedValueIndex && item.ReadOnly
            "
            class="mb-0 mt-4"
          >
            {{
              item.FileAlias[file][translatedValueIndex] || $t("No alias added")
            }}
          </p>
        </div>
      </v-layout>

      <p v-else-if="item.TranslatedFiles && !item.ReadOnly" class="mb-0">
        {{ item.FileAlias[file][getSetItemDefaultLanguage] }}
      </p>
      <v-text-field
        v-else
        v-model="item.FileAlias[file][getSetItemDefaultLanguage]"
        class="mr-2 mt-4 mb-3 max-width-alias-field"
        hide-details="auto"
        :label="$t('File alias')"
        outlined
        dense
      >
      </v-text-field>
    </v-layout>
    <v-layout class="mt-2" align-center>
      <v-btn
        depressed
        :loading="isDownloading"
        color="primary"
        icon
        @click="setItemForPreview(file)"
        v-if="!showPreview && includesPDFOrMP4(file)"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        depressed
        :loading="isDownloading"
        color="primary"
        icon
        @click="showPreview = false"
        v-else-if="showPreview && includesPDFOrMP4(file)"
      >
        <v-icon>mdi-eye-off</v-icon>
      </v-btn>
      <v-btn
        depressed
        :loading="isDownloading"
        color="primary"
        icon
        @click="downloadFile(file)"
      >
        <v-icon>mdi-file-download</v-icon>
      </v-btn>

      <v-btn
        v-if="
          !item.ReadOnly ||
          editItemAdmin ||
          ($store.getters.hasUserRoleForPageItem({
            role: 2,
          }) &&
            !userIsAdministrator)
        "
        depressed
        color="primaryred"
        icon
        :loading="isDownloading"
        @click="
          $emit('deleteFile', {
            value: item.TranslatedFiles
              ? item.Value[itemDefaultLanguage]
              : item.Value,
          })
        "
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-layout>
  </v-alert>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    websiteId: {
      type: String,
    },

    file: {
      type: [Array, Object, String],
    },
    editItemAdmin: {
      type: Boolean,
    },
    itemDefaultLanguage: {
      type: String,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      userIsAdministrator: this.$auth.userIsAdministrator(),
      showPreview: false,
      isDownloading: false,
      itemLanguages: this.getItemLanguages(),
      lang: this.itemDefaultLanguage,
    };
  },
  created() {
    if (this.item.ShowAlias) {
      if (!this.item.FileAlias) {
        this.$set(this.item, "FileAlias", {});
      }
      if (!this.item.FileAlias[this.file]) {
        this.$set(this.item.FileAlias, this.file, { Nl: "", Fr: "", En: "" });
      }
      if (
        !this.item.FileAlias[this.file]["Nl"] &&
        this.item.FileAlias[this.file]["Nl"] !== ""
      ) {
        this.$set(this.item.FileAlias, this.file, {
          Nl: this.item.FileAlias[this.file],
          Fr: "",
          En: "",
        });
      }
    }
  },
  computed: {
    getSetItemDefaultLanguage: {
      get() {
        return this.lang;
      },
      set(value) {
        this.lang = value;
      },
    },
    checkShowItem() {
      return this.item.ShowAlias;
    },
  },
  methods: {
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    downloadFile(file) {
      this.isDownloading = true;
      this.$request.download(
        "/files/" + this.websiteId + "/" + file + "?token=" + this.userToken,
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading = false;
        }
      );
    },
    includesPDFOrMP4(file) {
      return file.includes(".pdf") || file.includes(".mp4");
    },
    setItemForPreview(file) {
      this.$emit("setItemForPreview", file);
    },
  },
};
</script>

<style scoped>
.width-cols {
  max-width: 32rem !important;
}
.max-width-alias-field {
  max-width: 18rem !important;
}
</style>