var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-alert',{staticClass:"py-2 file-title-max-lenght",attrs:{"border":"left","color":"primary","colored-border":true}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-icon',[_vm._v("mdi-paperclip")]),_c('h3',{staticClass:"ml-1 overflow-auto"},[_vm._v(" "+_vm._s(_vm.file)+" ")])],1),(_vm.checkShowItem)?_c('v-layout',{staticClass:"mx-2"},[(!_vm.item.TranslatedFiles && !_vm.item.ReadOnly)?_c('v-select',{staticClass:"max-w-select-lang mr-2 mt-4",attrs:{"items":_vm.itemLanguages,"label":_vm.$t('Language'),"hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.getSetItemDefaultLanguage),callback:function ($$v) {_vm.getSetItemDefaultLanguage=$$v},expression:"getSetItemDefaultLanguage"}}):_vm._e(),(!_vm.item.TranslatedFiles)?_c('v-layout',{attrs:{"align-end":""}},_vm._l((_vm.item.FileAlias[_vm.file]),function(translatedVal,translatedValueIndex){return _c('div',{key:translatedValueIndex},[(
            _vm.getSetItemDefaultLanguage == translatedValueIndex &&
            !_vm.item.ReadOnly
          )?_c('v-text-field',{staticClass:"mr-2 mt-4 mb-3 max-width-alias-field",attrs:{"hide-details":"auto","label":_vm.$t('File alias'),"outlined":"","dense":""},model:{value:(_vm.item.FileAlias[_vm.file][translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.item.FileAlias[_vm.file], translatedValueIndex, $$v)},expression:"item.FileAlias[file][translatedValueIndex]"}}):(
            _vm.getSetItemDefaultLanguage == translatedValueIndex && _vm.item.ReadOnly
          )?_c('p',{staticClass:"mb-0 mt-4"},[_vm._v(" "+_vm._s(_vm.item.FileAlias[_vm.file][translatedValueIndex] || _vm.$t("No alias added"))+" ")]):_vm._e()],1)}),0):(_vm.item.TranslatedFiles && !_vm.item.ReadOnly)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.FileAlias[_vm.file][_vm.getSetItemDefaultLanguage])+" ")]):_c('v-text-field',{staticClass:"mr-2 mt-4 mb-3 max-width-alias-field",attrs:{"hide-details":"auto","label":_vm.$t('File alias'),"outlined":"","dense":""},model:{value:(_vm.item.FileAlias[_vm.file][_vm.getSetItemDefaultLanguage]),callback:function ($$v) {_vm.$set(_vm.item.FileAlias[_vm.file], _vm.getSetItemDefaultLanguage, $$v)},expression:"item.FileAlias[file][getSetItemDefaultLanguage]"}})],1):_vm._e(),_c('v-layout',{staticClass:"mt-2",attrs:{"align-center":""}},[(!_vm.showPreview && _vm.includesPDFOrMP4(_vm.file))?_c('v-btn',{attrs:{"depressed":"","loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){return _vm.setItemForPreview(_vm.file)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):(_vm.showPreview && _vm.includesPDFOrMP4(_vm.file))?_c('v-btn',{attrs:{"depressed":"","loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){_vm.showPreview = false}}},[_c('v-icon',[_vm._v("mdi-eye-off")])],1):_vm._e(),_c('v-btn',{attrs:{"depressed":"","loading":_vm.isDownloading,"color":"primary","icon":""},on:{"click":function($event){return _vm.downloadFile(_vm.file)}}},[_c('v-icon',[_vm._v("mdi-file-download")])],1),(
        !_vm.item.ReadOnly ||
        _vm.editItemAdmin ||
        (_vm.$store.getters.hasUserRoleForPageItem({
          role: 2,
        }) &&
          !_vm.userIsAdministrator)
      )?_c('v-btn',{attrs:{"depressed":"","color":"primaryred","icon":"","loading":_vm.isDownloading},on:{"click":function($event){return _vm.$emit('deleteFile', {
          value: _vm.item.TranslatedFiles
            ? _vm.item.Value[_vm.itemDefaultLanguage]
            : _vm.item.Value,
        })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }