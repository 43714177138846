<template>
  <div>
    <pageElementFilesDialog
      :key="websiteId + showPreviewItem"
      :websiteId="websiteId"
      :showPreviewItem="showPreviewItem"
      :showPreviewProp="showPreview"
      @update:showPreview="showPreview = $event"
    />
    <v-select
      v-if="item.TranslatedFiles"
      v-model="itemDefaultLanguage"
      :items="itemLanguages"
      :label="$t('Language')"
      class="max-w-select-lang"
      outlined
      dense
    ></v-select>
    <dragAndDrop
      v-if="item.NeedsDragAndDrop && (!item.ReadOnly || editItemAdmin)"
      @setSnackbar="$emit('setSnackbar', $event)"
      :multiple="true"
      :uploadUrl="'/content/fileupload/' + pageId"
      :needsUploadBtn="false"
      :autoUploadAfterSelect="true"
      :accepts="item.SpecificFileTypes"
      @returnFilename="addFile($event)"
      :showFilesInDropzone="false"
      :options="{
        height: getHeight,
      }"
      class="mb-4"
    />

    <!-- There are files in the list -->
    <template
      v-for="(file, index) in item.TranslatedFiles
        ? item.Value[itemDefaultLanguage]
        : item.Value"
    >
      <FileCard
        :key="index + file"
        v-if="file != ''"
        :websiteId="websiteId"
        :item="item"
        :file="file"
        :editItemAdmin="editItemAdmin"
        :itemDefaultLanguage="itemDefaultLanguage"
        @deleteFile="deleteFile($event.value, index)"
        @setItemForPreview="setItemForPreview($event)"
      />
    </template>
    <template v-for="(file, index) in files">
      <h3 :key="index">
        <v-icon>mdi-paperclip</v-icon>
        {{ file.name }}
        <v-btn
          v-if="!item.ReadOnly"
          depressed
          color="primaryred"
          icon
          @click="
            deleteFile(
              item.TranslatedFiles
                ? item.Value[itemDefaultLanguage]
                : item.Value,
              index
            )
          "
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </h3>
    </template>
    <input
      v-if="!item.ReadOnly || editItemAdmin"
      :ref="'filesInput' + objectName"
      class="fileInput"
      multiple
      type="file"
      :accept="item.SpecificFileTypes"
      @change="setSelectedFiles($event.target.files)"
    />
    <v-btn
      v-if="!item.ReadOnly || editItemAdmin"
      depressed
      class="mt-3 ml-0 mr-3 edit-page-action-button mb-4"
      @click="$refs['filesInput' + objectName].click()"
      color="primary"
      >{{ $t("Choose files") }}</v-btn
    >

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />

      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
          class="mt-2"
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.NeedsDragAndDrop"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Drag and drop')"
            ></v-switch>
            <v-text-field
              v-if="item.NeedsDragAndDrop"
              v-model.number="item.DragAndDropHeight"
              hide-details="auto"
              type="number"
              min="50"
              dense
              :label="$t('Drag and drop height')"
              class="mw-w mt-6"
            ></v-text-field>
            <v-switch
              v-model="item.TranslatedFiles"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Translate files')"
              @change="makeOneArray()"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ShowAlias"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('File alias')"
            ></v-switch>

            <v-switch
              flat
              :ripple="false"
              v-model="item.SpecificFile"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Set file types')"
            ></v-switch>

            <v-select
              v-if="item.SpecificFile"
              :items="$config.fileTypes"
              v-model="item.SpecificFileTypes"
              :label="$t('Files')"
              multiple
              chips
              clearable
              class="mt-6"
              outlined
              dense
              hide-details="auto"
            >
            </v-select>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import PageElementFilesDialog from "../pageElementFilesDialog.vue";
import FileCard from "../types-helpers/FileCard.vue";
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      userIsAdministrator: this.$auth.userIsAdministrator(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.$config.contentLanguages[0],
      showPreview: false,
      showPreviewItem: null,
      isDownloading: false,
      files: null,
      uploading: false,
      dragover: null,
      uploadError: null,
    };
  },
  components: {
    PageElementFilesDialog,
    FileCard,
  },
  computed: {
    getHeight() {
      if (this.item.DragAndDropHeight) {
        return this.item.DragAndDropHeight;
      }
      return "500";
    },
  },
  methods: {
    deleteFile(files, index) {
      if (this.item["TranslatedFiles"]) {
        if (this.item.Value[this.itemDefaultLanguage]) {
          this.$delete(this.item.Value[this.itemDefaultLanguage], index);
        }
      } else {
        if (this.item.Value) {
          this.$delete(this.item.Value, index);
        }
      }
      this.$forceUpdate();
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    setItemForPreview(file) {
      this.showPreviewItem = file;
      this.showPreview = true;
    },

    setSelectedFiles(fileList) {
      this.uploading = true;
      this.$emit("update:saving", true);

      this.files = Array.prototype.slice.call(fileList);

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      this.$request.post(
        "/content/fileupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$refs["filesInput" + this.objectName].value = null;
          this.$emit("update:saving", false);

          if (res.success) {
            const toaster = {
                message: this.$t("File successfully uploaded"),
                timeout: false,
                type: "SUCCESS",
                toastClassName: ["toaster-color-success", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-check-circle",
              };
            this.$store.dispatch("setToaster", toaster);
            this.addFile(res.data);
          } else {
            this.files = null;
            this.uploadError = res.message;
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    addFile(event) {
      if (this.item["TranslatedFiles"]) {
        if (this.item.Value[this.itemDefaultLanguage]) {
          this.item.Value[this.itemDefaultLanguage].push(...event);
        } else if (!this.item.Value) {
          this.item.Value = {};
          this.item.Value[this.itemDefaultLanguage] = event;
        } else {
          this.item.Value[this.itemDefaultLanguage] = {};
          this.item.Value[this.itemDefaultLanguage] = event;
        }
      } else {
        if (this.item.Value) {
          this.item.Value = this.item.Value.concat(event);
        } else {
          this.item.Value = event;
        }
      }
      this.files = null;
    },
    makeOneArray() {
      if (this.item.Value) {
        if (this.item.TranslatedFiles) {
          this.item.Value = { Nl: this.item.Value };
        } else {
          this.item.Value =
            this.item.Value["Nl"] ||
            this.item.Value["Fr"] ||
            this.item.Value["En"];
        }
      }
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 3rem;
  max-width: 12rem;
}
</style>